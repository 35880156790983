import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { API_HOST } from './constants';
import { refreshCurrentSession } from './useAuth';

const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getCmsReservationList: {
          keyArgs: ['accommodationId', 'filter'],
        },
      },
    },
  },
});

const errorLink = onError(
  ({
    graphQLErrors,
    networkError,
    operation,
    forward,
    // eslint-disable-next-line consistent-return
  }) => {
    if (graphQLErrors) {
      let needRefreshToken: boolean = false;
      let needRefreshSubscription: boolean = false;

      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        needRefreshToken =
          typeof path === 'string' && message.includes('401 Access denied');
        if (
          needRefreshToken &&
          /subscription/i.test(path as unknown as string)
        ) {
          needRefreshSubscription = true;
        }
      });

      if (needRefreshToken) {
        return (
          fromPromise(refreshCurrentSession())
            .filter((value) => Boolean(value))
            // @ts-ignore
            .flatMap(() => {
              if (needRefreshSubscription) {
                console.log(
                  'Refreshing token due to subscription authorize error.'
                );
                window.location.reload();
              } else {
                forward(operation);
              }
            })
        );
      }

      refreshCurrentSession();
    }
    if (networkError) {
      if ((networkError as ServerError).statusCode === 401) {
        return fromPromise(refreshCurrentSession())
          .filter((value) => Boolean(value))
          .flatMap(() => forward(operation));
      }
      console.log('[Network error]: ', networkError);
    }
  }
);

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => {
          const token = localStorage.getItem('token');
          const service = 'gds_admin';
          if (token) {
            oper.setContext({
              headers: {
                Authorization: `${token}` || null,
                service,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

console.log(API_HOST);
export const apolloClient = new ApolloClient({
  uri: API_HOST,
  link: ApolloLink.from([
    errorLink,
    requestLink,
    createUploadLink({
      uri: `${API_HOST}/graphql`,
    }),
  ]),
  cache: apolloCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});
