export const API_HOST = process.env.REACT_APP_API_HOST || '';
export const PPMS_HOST = process.env.REACT_APP_PPMS_HOST || '';

export const S3_ENDPOINT_IMAGE = process.env.REACT_APP_S3_ENDPOINT_IMAGE || '';

export const IMAGE_CDN_HOST = process.env.REACT_APP_IMAGE_CDN_HOST || '';

export const PRIOR_CHANNEL_DOMESTIC = [13, 2, 20, 74, 77, 80, 71];

export const PRIOR_CHANNEL_OVERSEA = [24, 88, 106, 122, 108, 124, 75];

export const PRIOR_CHANNEL_DEFAULT = [4, 11, 15, 31, 32, 33, 34, 36, 53, 72, 73, 79, 94, 97, 102, 109, 110, 117, 119, 125, 126, 128, 136, 154, 155, 159, 160, 163, 202, 205, 206, 161];

export const PRIOR_CHANNEL_B2E = [152, 144, 162, 176, 175, 183, 203];

export const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_STAGING_BUILD === 'true';

export const PLUS_GSHEET_IDS = {
  MODIFY_CHANNEL: isDevelopment ? '1TlYhOPa5FYjRbTA6ytFIe5ViVWetmdCrtAtq7FOmUjY' : '1rsJPe2UQdZvxZX3vSMEbzBV1zVERQKIBhgQm0MA4X6E',
  MODIFY_VENDOR_FEE: isDevelopment ? '' : '1i-I5_aO3voPev4PF9gLRdsTjBOI2g191_H8F7LAu4i4',
}

export const PERMERNENT_STATE_KEY = {
  APP_REFRESH_REQUESTED_CAUSE_CUNK_ERROR:
    '__PLUS_ADMIN_REFRESH_REQUESTED_CAUSE_CUNK_ERROR__',
  APP_ADMIN_MODE: '__PLUS_ADMIN_ADMIN_MODE__',
} as const;

export const STORAGE_KEY = {
  ACCESS_TOKEN: '__PPMS_ADMIN__ACCESS_TOKEN',
  REFRESH_TOKEN: '__PPMS_ADMIN__REFRESH_TOKEN',
};

export const ROUTE_PATH = {
  SIGN_IN: '/signin',
  PROPERTY_LIST: '/property/list',
  PROPERTY_DETAIL: '/property/detail/:propertyId',
  PROPERTY_DOWNLOAD: '/property/download',
  RESERVATION_LIST: '/reservation/list',
  RESERVATION_DETAIL: '/reservation/detail/:reservationId',
  PARTNER_INQUIRIES: '/partner-inquiry/list',
  PARTNER_INQUIRY_DETAIL: '/partner-inquiry/detail/:inquiryId',
  PARTNER_INQUIRY_TEMPLATES: '/partner-inquiry/templates',
  PARTNER_INQUIRY_TEMPLATES_DETAIL: '/partner-inquiry/templates/:templateId',
  SMS_CHARGE_LIST: '/sms-charge/list',
  NOTICE_LIST: '/notice/list',
  NOTICE_DETAIL: '/notice/detail/:noticeId',
  REPORT: '/report',
};

export enum ONLINE_PAYMENT_METHODS {
  WIRED = 'wired',
  CARD = 'card',
  KEYIN = 'keyin',
  AGENCY = 'agency',
}

export const CODE_TYPES = [
  'propertyCodes',
  'facilityCodes',
  'serviceCodes',
  'attractionCodes',
] as const;

export const validationRegex = {
  phone: /^0\d{2,3}-?\d{3,4}-?\d{4}$/,
  telephone: /^(\d{2,3}-?)?\d{3,4}-?\d{4}$/,
  email: /^[\w.-]*@[\w.가-힣-]*\.[A-Za-z가-힣]{1,8}$/,
};

export const PPMS_VENDOR = [
  7, 9, 11, 12, 13, 15, 16, 17, 22, 23, 37, 49, 58, 76, 79, 82, 121, 123, 129,
  130, 131, 137, 143, 144, 148, 171, 193, 204, 205, 209, 218,
];

interface IBank {
  bank: string;
  korean_code: string;
  code: string;
  korean_name: string;
  english_name: string;
}

export const BANK_CODES: IBank[] = [
  {
    'bank': 'DB금융투자',
    'korean_code': '279',
    'code': 'SI',
    'korean_name': 'DB금융투자',
    'english_name': 'DB_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': 'HSBC',
    'korean_code': '054',
    'code': '54',
    'korean_name': '-',
    'english_name': 'HSBC',
  },
  {
    'bank': 'KB증권',
    'korean_code': '218',
    'code': 'S4',
    'korean_name': 'KB증권',
    'english_name': 'KB_SECURITIES',
  },
  {
    'bank': 'KDB산업은행',
    'korean_code': '002',
    'code': '02',
    'korean_name': '산업',
    'english_name': 'KDBBank',
  },
  {
    'bank': 'KEB하나은행',
    'korean_code': '081',
    'code': '81',
    'korean_name': '하나',
    'english_name': 'Hana',
  },
  {
    'bank': 'KTB투자증권',
    'korean_code': '227',
    'code': 'SP',
    'korean_name': 'KTB투자증권',
    'english_name': 'DAOL_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': 'K뱅크',
    'korean_code': '089',
    'code': '89',
    'korean_name': '케이',
    'english_name': 'KBank',
  },
  {
    'bank': 'NH투자증권',
    'korean_code': '247',
    'code': 'SL',
    'korean_name': 'NH투자증권',
    'english_name': 'NH_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': 'SK증권',
    'korean_code': '266',
    'code': 'SD',
    'korean_name': 'SK증권',
    'english_name': 'SK_SECURITIES',
  },
  {
    'bank': '경남은행',
    'korean_code': '039',
    'code': '39',
    'korean_name': '경남',
    'english_name': 'KyongnamBank',
  },
  {
    'bank': '광주은행',
    'korean_code': '034',
    'code': '34',
    'korean_name': '광주',
    'english_name': 'GwangjuBank',
  },
  {
    'bank': '교보증권',
    'korean_code': '261',
    'code': 'S8',
    'korean_name': '교보증권',
    'english_name': 'KYOBO_SECURITIES',
  },
  {
    'bank': '국민은행',
    'korean_code': '004',
    'code': '06',
    'korean_name': '국민',
    'english_name': 'Kookmin',
  },
  {
    'bank': '기업은행',
    'korean_code': '003',
    'code': '03',
    'korean_name': '기업',
    'english_name': 'IBK',
  },
  {
    'bank': '농협',
    'korean_code': '011',
    'code': '11',
    'korean_name': '농협',
    'english_name': 'Nonghyeop',
  },
  {
    'bank': 'iM뱅크(구 대구은행)',
    'korean_code': '031',
    'code': '31',
    'korean_name': '대구',
    'english_name': 'DaeguBank',
  },
  {
    'bank': '대신증권',
    'korean_code': '267',
    'code': 'SE',
    'korean_name': '대신증권',
    'english_name': 'DAISHIN_SECURITIES',
  },
  {
    'bank': '메리츠증권',
    'korean_code': '287',
    'code': 'SK',
    'korean_name': '메리츠증권',
    'english_name': 'MERITZ_SECURITIES',
  },
  {
    'bank': '미래에셋대우',
    'korean_code': '238',
    'code': 'S5',
    'korean_name': '미래에셋증권',
    'english_name': 'MIRAE_ASSET_SECURITIES',
  },
  {
    'bank': '부국증권',
    'korean_code': '290',
    'code': 'SM',
    'korean_name': '부국',
    'english_name': 'BOOKOOK_SECURITIES',
  },
  {
    'bank': '부산은행',
    'korean_code': '032',
    'code': '32',
    'korean_name': '부산',
    'english_name': 'BusanBank',
  },
  {
    'bank': '산림조합중앙회',
    'korean_code': '064',
    'code': '64',
    'korean_name': '산림',
    'english_name': 'Sanlim',
  },
  {
    'bank': '삼성증권',
    'korean_code': '240',
    'code': 'S3',
    'korean_name': '삼성증권',
    'english_name': 'SAMSUNG_SECURITIES',
  },
  {
    'bank': '새마을',
    'korean_code': '045',
    'code': '45',
    'korean_name': '새마을',
    'english_name': 'Saemaul',
  },
  {
    'bank': '수협',
    'korean_code': '007',
    'code': '07',
    'korean_name': '수협',
    'english_name': 'Suhyeop',
  },
  {
    'bank': '신영증권',
    'korean_code': '291',
    'code': 'SN',
    'korean_name': '신영증권',
    'english_name': 'SHINYOUNG_SECURITIES',
  },
  {
    'bank': '신한금융투자',
    'korean_code': '278',
    'code': 'S2',
    'korean_name': '신한금융투자',
    'english_name': 'SHINHAN_INVESTMENT',
  },
  {
    'bank': '신한은행',
    'korean_code': '088',
    'code': '88',
    'korean_name': '신한',
    'english_name': 'Shinhan',
  },
  {
    'bank': '신협중앙회',
    'korean_code': '048',
    'code': '48',
    'korean_name': '신협',
    'english_name': 'Shinhyeop',
  },
  {
    'bank': '씨티은행',
    'korean_code': '027',
    'code': '27',
    'korean_name': '씨티',
    'english_name': 'Citi',
  },
  {
    'bank': '우리은행',
    'korean_code': '020',
    'code': '20',
    'korean_name': '우리',
    'english_name': 'Woori',
  },
  {
    'bank': '우체국',
    'korean_code': '071',
    'code': '71',
    'korean_name': '우체국',
    'english_name': 'Post',
  },
  {
    'bank': '유안타증권',
    'korean_code': '209',
    'code': 'S0',
    'korean_name': '유안타증권',
    'english_name': 'YUANTA_SECURITES',
  },
  {
    'bank': '유진투자증권',
    'korean_code': '280',
    'code': 'SJ',
    'korean_name': '유진투자증권',
    'english_name': 'EUGENE_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': '저축은행',
    'korean_code': '050',
    'code': '50',
    'korean_name': '저축',
    'english_name': 'SavingBank',
  },
  {
    'bank': '전북은행',
    'korean_code': '037',
    'code': '37',
    'korean_name': '전북',
    'english_name': 'JeonbukBank',
  },
  {
    'bank': '제일은행',
    'korean_code': '023',
    'code': '23',
    'korean_name': 'SC제일',
    'english_name': 'SC',
  },
  {
    'bank': '제주은행',
    'korean_code': '035',
    'code': '35',
    'korean_name': '제주',
    'english_name': 'JejuBank',
  },
  {
    'bank': '지역농축협',
    'korean_code': '012',
    'code': '12',
    'korean_name': '단위농협',
    'english_name': 'LocalNonghyeop',
  },
  {
    'bank': '카카오뱅크',
    'korean_code': '090',
    'code': '90',
    'korean_name': '카카오',
    'english_name': 'KakaoBank',
  },
  {
    'bank': '카카오페이증권',
    'korean_code': '288',
    'code': 'SQ',
    'korean_name': '카카오페이증권',
    'english_name': 'KAKAOPAY_SECURITIES',
  },
  {
    'bank': '키움증권',
    'korean_code': '264',
    'code': 'SB',
    'korean_name': '키움증권',
    'english_name': 'KIWOOM',
  },
  {
    'bank': '토스뱅크',
    'korean_code': '092',
    'code': '92',
    'korean_name': '토스뱅크',
    'english_name': 'TossBank',
  },
  {
    'bank': '하나금융투자',
    'korean_code': '270',
    'code': 'SH',
    'korean_name': '하나금융투자',
    'english_name': 'HANA_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': '하이투자',
    'korean_code': '262',
    'code': 'S9',
    'korean_name': '하이투자증권',
    'english_name': 'HI_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': '한국포스증권',
    'korean_code': '294',
    'code': 'SR',
    'korean_name': '한국포스증권',
    'english_name': 'KOREA_FOSS_SECURITIES',
  },
  {
    'bank': '한화증권',
    'korean_code': '269',
    'code': 'SG',
    'korean_name': '한화투자증권',
    'english_name': 'HANWHA_INVESTMENT_AND_SECURITIES',
  },
  {
    'bank': '현대차증권',
    'korean_code': '263',
    'code': 'SA',
    'korean_name': '현대차증권',
    'english_name': 'HYUNDAI_MOTOR_SECURITIES',
  },
];
