import { gql } from '@apollo/client';

export const TportQueryVendorRows = gql`
  query TportQueryVendors {
    vendorList {
      id
      vendor_status
      vendor_alias
      vendor_cname
      vendor_type
    }
  }
`;
