import React, { useEffect, useState } from 'react';

export const TimeoutRender = <T extends any>({
  ms = 1500,
  children,
  WatingSlot = <></>,
}: {
  ms?: number;
  children: T;
  WatingSlot?: JSX.Element;
}): T | JSX.Element => {
  const [needRender, setNeedRender] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setNeedRender(true), ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return needRender ? children : WatingSlot;
};
