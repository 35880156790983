import { Outlet } from 'react-router-dom';
import React from 'react';
import { DashboardLeftSidebar } from './sidebar';
import { DashboardHeader } from './header';

const DashboardLayout = () => {
  return (
    <div className="flex flex-row h-full w-full">
      <DashboardLeftSidebar />
      <div
        id="plus-layout-content-main"
        className="flex flex-col w-full h-full px-10 py-8 pt-0 min-w-[960px] max-h-[100%] overflow-auto"
      >
        <DashboardHeader />
        <main className="flex-shrink flex-grow w-full min-h-[520px] overflow-visible">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
