import { SelectBoxHeader } from 'components/ui/SelectBox';
import { useDetailPath } from 'hooks/useDetailPath';
import { useVendorList } from 'hooks/useVendorList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVendorSelectionStore } from 'stores/vendorSelectionStore.store';

export const DashboardHeader = () => {
  const { t } = useTranslation();
  const detailPath = useDetailPath();
  const vendorList = useVendorList();
  const selectedGlobalVendor = useVendorSelectionStore(
    (state) => state.selectedGlobalVendor
  );
  const onChangeVendor = useVendorSelectionStore((state) => state.selectVendor);

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full h-88 shrink-0" />
      <div className="flex flex-row justify-between items-center w-auto h-88 fixed top-0 px-10 pr-6 left-[240px] right-4 bg-white z-[500]">
        <p className="text-sub2 font-bold">
          {t(`dashboard.header.${detailPath}`)}
        </p>
        <SelectBoxHeader
          value={selectedGlobalVendor}
          className="w-[240px] bg-bg-gray"
          options={[
            {
              label: '전체보기',
              value: '',
            },
            ...vendorList.map((vendor) => ({
              label: `${vendor.id}. ${vendor.vendor_alias}`,
              value: vendor.id,
            })),
          ]}
          onChange={(value) => onChangeVendor(value)}
        />
      </div>
    </>
  );
};
