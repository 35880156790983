import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nextOptions: InitOptions<HttpBackendOptions> = {
  debug: true,
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
  lng: 'ko',
  ns: 'plus-admin',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextOptions);

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(i18nextOptions);
}

export default i18n;
