/* eslint-disable no-restricted-imports */
import { ROUTE_PATH } from 'helpers/constants';
import DashboardLayout from 'pages/Dashboard/layout';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

const SignInPage = React.lazy(() => import('pages/SignIn'));
const PropertyListPage = React.lazy(
  () => import('pages/PropertyList/PropertyList')
);
const PropertyDownloadPage = React.lazy(
  () => import('pages/PropertyDownload/PropertyDownload')
);
const ReservationListPage = React.lazy(
  () => import('pages/ReservationList/ReservationList')
);
const PartnerInquiriesPage = React.lazy(
  () => import('pages/PartnerInquiries/PartnerInquiries')
);
const PartnerInquiryTemplatesPage = React.lazy(
  () => import('pages/PartnerInquiryTemplates/PartnerInquiryTemplates')
);
const SmsChargeListPage = React.lazy(
  () => import('pages/SmsChargeList/SmsChargeList')
);
const NoticesPage = React.lazy(() => import('pages/Notices/Notices'));
const ReportPage = React.lazy(() => import('pages/Report/Report'));

const AppRoutes = (
  <>
    <Route element={<DashboardLayout />}>
      <Route
        path={ROUTE_PATH.RESERVATION_LIST}
        element={<ReservationListPage />}
      />
      <Route
        path={ROUTE_PATH.RESERVATION_DETAIL}
        element={<ReservationListPage />}
      />
      <Route path={ROUTE_PATH.PROPERTY_LIST} element={<PropertyListPage />} />
      <Route path={ROUTE_PATH.PROPERTY_DETAIL} element={<PropertyListPage />} />
      <Route
        path={ROUTE_PATH.PROPERTY_DOWNLOAD}
        element={<PropertyDownloadPage />}
      />
      <Route
        path={ROUTE_PATH.PARTNER_INQUIRIES}
        element={
          <ProtectedRoute
            element={<PartnerInquiriesPage />}
            permissions={['admin']}
          />
        }
      />
      <Route
        path={ROUTE_PATH.PARTNER_INQUIRY_DETAIL}
        element={
          <ProtectedRoute
            element={<PartnerInquiriesPage />}
            permissions={['admin']}
          />
        }
      />
      <Route
        path={ROUTE_PATH.PARTNER_INQUIRY_TEMPLATES}
        element={
          <ProtectedRoute
            element={<PartnerInquiryTemplatesPage />}
            permissions={['admin']}
          />
        }
      />
      <Route
        path={ROUTE_PATH.PARTNER_INQUIRY_TEMPLATES_DETAIL}
        element={
          <ProtectedRoute
            element={<PartnerInquiryTemplatesPage />}
            permissions={['admin']}
          />
        }
      />
      <Route
        path={ROUTE_PATH.SMS_CHARGE_LIST}
        element={
          <ProtectedRoute
            element={<SmsChargeListPage />}
            permissions={['admin']}
          />
        }
      />
      <Route
        path={ROUTE_PATH.NOTICE_LIST}
        element={
          <ProtectedRoute element={<NoticesPage />} permissions={['admin']} />
        }
      />
      <Route
        path={ROUTE_PATH.NOTICE_DETAIL}
        element={
          <ProtectedRoute element={<NoticesPage />} permissions={['admin']} />
        }
      />
      <Route path={ROUTE_PATH.REPORT} element={<ReportPage />} />
    </Route>
    <Route
      path={ROUTE_PATH.SIGN_IN}
      element={
        <>
          <SignInPage />
        </>
      }
    />

    <Route path="*" element={<Navigate replace to={ROUTE_PATH.SIGN_IN} />} />
  </>
);

export default AppRoutes;
