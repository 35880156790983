import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Auth, Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { ProvideAuthProps, UseAuth } from 'types/common.type';
import { UserAuthority } from 'types/auth.type';

const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;

const CacheUserToken = gql`
  mutation CacheUserToken($property_id: ID!) {
    putMeInThere(property_id: $property_id)
  }
`;

const QueryUserPermission = gql`
  query QueryUserPermission {
    permission {
      resources
    }
  }
`;

interface PermissionResource {
  vendor: number[];
  channel: unknown;
  property: unknown;
  review: unknown;
}

const authOptions: {
  userPoolId?: string;
  userPoolWebClientId?: string;
} = {};

authOptions.userPoolId = userPoolId;
authOptions.userPoolWebClientId = userPoolWebClientId;

Amplify.configure({
  Auth: {
    region: 'ap-northeast-2',
    ...authOptions,
  },
});

const AuthContext = createContext({} as UseAuth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const refreshCurrentSession = async (): Promise<{
  success: boolean;
  jwtToken?: string;
  refreshToken?: string;
  user?: any;
}> => {
  try {
    const user = await Auth.currentSession();
    const jwtToken = user.getIdToken().getJwtToken();
    const refreshToken = user.getRefreshToken().getToken();

    localStorage.setItem('token', jwtToken);
    localStorage.setItem('refreshToken', refreshToken);

    return {
      success: true,
      jwtToken,
      refreshToken,
      user,
    };
  } catch (err) {
    console.log(err);

    return {
      success: false,
    };
  }
};

type PermissionQueryResponse = {
  permission: { resources: PermissionResource & string };
};

const useProvideAuth = (): UseAuth => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [username, setUsername] = useState('');
  const [vendorIds, setVendorIds] = useState<(number | string)[]>([]);
  const [savedPermission, setSavedPermission] = useState('loading');
  const [getToken] = useMutation<{ putMeInThere: string }>(CacheUserToken);
  const [getUserPermission] = useLazyQuery<PermissionQueryResponse>(
    QueryUserPermission,
    {
      fetchPolicy: 'network-only',
    }
  );

  const getPermission = useCallback(
    async (fetchedData?: PermissionQueryResponse) => {
      let data = fetchedData;
      if (!data) {
        ({ data } = await getUserPermission());
      }
      const result = data?.permission.resources;

      if (result === '*') return UserAuthority.ADMIN;
      if (result?.vendor) return UserAuthority.VENDOR;
      if (result?.channel) return UserAuthority.CHANNEL;
      if (result?.property) {
        if (result.property === '*') return UserAuthority.REVIEW;
        return UserAuthority.PROPERTY;
      }
      if (result?.review) return UserAuthority.REVIEW;

      return UserAuthority.GUEST;
    },
    [getUserPermission]
  );

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setIsAuthenticated(false);
      navigate(`/signin`);
      return;
    }
    refreshCurrentSession().then(({ success }) => {
      if (success) {
        setIsAuthenticated(true);
        getUserPermission().then(({ data }) => {
          const res = data?.permission.resources;
          const resVendorIds: (number | string)[] = [];

          if (res === '*') {
            resVendorIds.push('*');
          }
          if (res?.vendor) {
            resVendorIds.push(...res.vendor);
          }
          setVendorIds(resVendorIds);
        });
      } else {
        setIsAuthenticated(false);
        navigate(`/signin`);
      }
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getPermission().then((permission) => {
      setSavedPermission(permission);
    });
  }, [isAuthenticated]);

  const signIn = useCallback(
    async (userId: string, password: string) => {
      try {
        const result = await Auth.signIn(userId, password);
        const jwtToken = result.signInUserSession.idToken.jwtToken as string;
        const refreshToken = result.signInUserSession.refreshToken
          .token as string;

        localStorage.setItem('token', jwtToken);
        localStorage.setItem('refreshToken', refreshToken);

        setUsername(result.username);
        setIsAuthenticated(true);

        const permission = await getUserPermission();
        const res = permission.data?.permission.resources;
        const resVendorIds: (number | string)[] = [];

        if (res === '*') {
          resVendorIds.push('*');
        }
        if (res?.vendor) {
          resVendorIds.push(...res.vendor);
        }
        setVendorIds(resVendorIds);

        return {
          success: true,
          message: 'Login Success',
          permission,
        };
      } catch {
        return {
          success: false,
          message: 'Login Fail',
        };
      }
    },
    [getUserPermission]
  );

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();

      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      setUsername('');
      setVendorIds([]);
      setIsAuthenticated(false);

      window.location.reload();

      return { success: true, message: '' };
    } catch {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  }, []);

  const getPropertyToken = useCallback(
    async (propertyId: number) => {
      const { data } = await getToken({
        variables: { property_id: propertyId },
      });

      return data?.putMeInThere ?? '';
    },
    [getToken]
  );

  const getVendorIds = useCallback(() => {
    return vendorIds;
  }, [vendorIds]);

  return useMemo(
    () => ({
      username,
      permission: savedPermission,
      isAuthenticated,
      signIn,
      signOut,
      getPropertyToken,
      getPermission,
      getVendorIds,
    }),
    [
      username,
      savedPermission,
      isAuthenticated,
      signIn,
      signOut,
      getPropertyToken,
      getPermission,
      getVendorIds,
    ]
  );
};

export function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
