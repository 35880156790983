import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useInsertionEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { theme } from 'twin.macro';
import { isDevelopment } from 'helpers/constants';
import { Channel, Debounce } from 'helpers/utils';

export const useChannel = <Message extends any>() => {
  const channel = useMemo(() => new Channel<Message>(), []);
  useEffect(() => () => channel.clear(), []);

  return channel;
};

export const useDebounce = (needWithoutClear: boolean = false) => {
  const needWithoutClearRef = useRef<boolean>(needWithoutClear);
  useEffect(() => {
    needWithoutClearRef.current = needWithoutClear;
  }, [needWithoutClear]);

  const debouncer = useMemo(() => new Debounce(), []);
  useEffect(
    () => () => {
      if (needWithoutClearRef.current) debouncer.clear();
    },
    []
  );

  return debouncer;
};

export const useStateRef = <T, SETTER = SetStateAction<T>>(initialValue: T) => {
  const [state, originStateSetter] = useState(initialValue);
  const stateRef = useRef(state);

  const stateSetter = useCallback(
    (setterOrUpdater: SETTER) => {
      const nextState =
        typeof setterOrUpdater === 'function'
          ? setterOrUpdater(state)
          : setterOrUpdater;
      stateRef.current = nextState;

      return originStateSetter(nextState);
    },
    [originStateSetter]
  );

  return [state, stateSetter, stateRef] as const;
};

type BreakPoint = 'mobile' | 'tablet' | 'pc';
/**
 * 결과값이 'tablet'이면 (max-width: theme`screens.tablet`) css media query와 일치합니다.
 * 다만 mobile은 320px로 매우 비좁으므로 'foldable'로 대신 판단합니다.
 */
export const useScreenQuery = (breakPoint: BreakPoint): boolean => {
  const mediaQueryList = useMemo(() => {
    const getBreakPointValue = (stringPixelValue: string) =>
      parseInt(stringPixelValue.replace('px', ''));
    const breakPointMap = new Map<BreakPoint, number>([
      // twin macro는 빌드타임에 치환되므로 정적으로 가져와야 합니다.
      ['mobile', getBreakPointValue(theme`screens.sm`)],
      ['tablet', getBreakPointValue(theme`screens.lg`)],
      ['pc', getBreakPointValue(theme`screens.lg`) + 1],
    ]);

    return window.matchMedia(
      `(${(breakPoint === 'pc' && 'min') || 'max'}-width: ${breakPointMap.get(
        breakPoint
      )}px)`
    );
  }, [breakPoint]);

  const [isMatch, setIsMatch] = useState(mediaQueryList.matches);

  useInsertionEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setIsMatch(e.matches);
    };
    mediaQueryList.addEventListener('change', listener);

    return () => mediaQueryList.removeEventListener('change', listener);
  }, [mediaQueryList]);

  return isMatch;
};

export const useAriaHasPopupGeneric = (
  role: React.AriaRole,
  isOpen: boolean
) => {
  const id = useId();
  const [triggerId, menuId] = useMemo(
    () => [`${id}-trigger`, `${id}-menu`],
    [id]
  );

  return useMemo(
    () => ({
      trigger: {
        id: triggerId,
        'aria-haspopup': true,
        'aria-controls': menuId,
        'aria-expanded': isOpen,
      },
      menu: {
        id: menuId,
        role,
        'aria-labelledby': triggerId,
      },
    }),
    [isOpen, role, triggerId, menuId]
  );
};

export const useSynceRefToState = <T>(observeState: T) => {
  const stateRef = useRef(observeState);
  useInsertionEffect(() => {
    stateRef.current = observeState;
  }, [observeState]);

  return stateRef;
};

export const useDangerousDocumentScrollLock = (
  shouldLock: boolean,
  needClear = true
) => {
  const needClearRef = useSynceRefToState(needClear);
  useEffect(() => {
    if (shouldLock) {
      document.documentElement.style.maxHeight = '100vh';
      document.documentElement.style.maxWidth = '100vw';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.maxHeight = '';
      document.documentElement.style.maxWidth = '';
      document.documentElement.style.overflow = '';
    }
  }, [shouldLock]);
  useEffect(
    () => () => {
      if (!needClearRef.current) return;
      document.documentElement.style.maxHeight = '';
      document.documentElement.style.maxWidth = '';
      document.documentElement.style.overflow = '';
    },
    []
  );
};

export const useReturnFocus = (
  isPopupOpen: boolean,
  referenceEl?: HTMLElement | null
) => {
  const trigger = useRef<HTMLElement | Element | undefined | null>();

  useLayoutEffect(() => {
    if (!isPopupOpen) return;
    trigger.current = referenceEl || document.activeElement;
  }, [isPopupOpen, referenceEl]);

  useEffect(() => {
    if (!(trigger.current instanceof HTMLElement)) return;
    trigger.current.focus();
    trigger.current = undefined;
  }, [isPopupOpen]);
};

/**
 * esc키 누를경우, window에 click이벤트가 일어날 경우 false로 변경합니다.
 * 컨텐츠의 부모, trigger element에 stopPropagation을 사용해주세요
 */
export const useNormallyCloseModalInteraction = (
  [isOpen, setIsOpen]: [boolean, Dispatch<boolean>],
  { shouldCloseWhenWindowClick }: { shouldCloseWhenWindowClick?: boolean } = {}
) => {
  const setIsOpenRef = useSynceRefToState(setIsOpen);
  const close = useCallback(() => setIsOpenRef.current(false), []);

  useEffect(() => {
    if (!isOpen) return () => {};

    const closeEvent = (e: KeyboardEvent | Event) => {
      if (e instanceof KeyboardEvent && e.key.toLowerCase() === 'escape') {
        setIsOpenRef.current(false);
        return;
      }

      if (e instanceof MouseEvent && shouldCloseWhenWindowClick) {
        setIsOpenRef.current(false);
      }
    };
    if (shouldCloseWhenWindowClick)
      window.addEventListener('click', closeEvent);
    window.addEventListener('keydown', closeEvent);

    return () => {
      window.removeEventListener('click', closeEvent);
      window.removeEventListener('keydown', closeEvent);
    };
  }, [isOpen, shouldCloseWhenWindowClick]);

  return close;
};

export const useDangerousRootAnimation = ({
  needAnimation,
  type,
  transitionOption,
}: {
  needAnimation: boolean;
  type: 'slideToLeft';
  transitionOption: string;
}) => {
  const nextFrameRef = useRef<NodeJS.Timeout>();

  useLayoutEffect(() => {
    const rootElement = document.getElementById('root') as HTMLElement;
    if (isDevelopment && !rootElement)
      throw new Error(
        'can\'t find react render root that has "root" as element\'s id'
      );

    const clearTransition = () => {
      rootElement.style.transition = '';
    };
    if (needAnimation) {
      rootElement.style.transition = `transform ${transitionOption}`;
      // requestAnimationFrame은 보장하지 못합니다.
      nextFrameRef.current = setTimeout(() => {
        rootElement.style.transform = 'translateX(-100%)';
        rootElement.addEventListener('transitionend', clearTransition, {
          once: true,
        });
      }, 12);
    }

    return () => {
      if (nextFrameRef.current) {
        clearTimeout(nextFrameRef.current);
        rootElement.style.transition = `transform ${transitionOption}`;
      }
      if (rootElement.style.transform) {
        nextFrameRef.current = setTimeout(() => {
          rootElement.style.transform = '';
          rootElement.addEventListener('transitionend', clearTransition, {
            once: true,
          });
        }, 12);
      }
    };
  }, [needAnimation, type, transitionOption]);
};

export const useControlUncontrolState = <T extends any>({
  initial,
  state: controlled,
  setState: setControlledState,
}:
  | { initial: T; state?: T; setState?: SetStateAction<Dispatch<T>> }
  | { initial?: T; state: T; setState: SetStateAction<Dispatch<T>> }) => {
  const [uncontrolled, setUncontrolled] = useState(initial ?? controlled);

  return [
    controlled ?? uncontrolled,
    setControlledState ?? setUncontrolled,
  ] as const;
};

export const usePermernentState = <T>({
  key,
  decode,
  encode,
  storage = localStorage,
}: {
  key: string;
  decode: (v: string | null) => T;
  encode: (v: NonNullable<T>) => string;
  storage?: Storage;
}): [T, Dispatch<SetStateAction<T | undefined | null>>] => {
  const storageAction = useCallback(
    (newState: T | undefined | null) => {
      if (newState !== null && newState !== undefined) {
        storage.setItem(key, encode(newState));
      } else {
        storage.removeItem(key);
      }
    },
    [key, storage, encode]
  );

  const [parsedState, setParsedState] = useState<T>(() =>
    decode(storage.getItem(key))
  );
  const setWithStorage = useCallback<
    Dispatch<SetStateAction<T | undefined | null>>
  >(
    (setterOrUpdator) => {
      if (typeof setterOrUpdator !== 'function') {
        storageAction(setterOrUpdator);
        setParsedState(setterOrUpdator as T);
        return;
      }

      setParsedState((prevValue) => {
        const newValue = (setterOrUpdator as (p: T) => T)(prevValue);
        storageAction(newValue);

        return newValue;
      });
    },
    [storageAction]
  );

  useInsertionEffect(() => {
    storageAction(parsedState);
  }, [parsedState, storageAction]);

  return [parsedState, setWithStorage];
};

export const usePrevState = <T extends any>(watchState: T) => {
  const ref = useRef(watchState);

  useInsertionEffect(
    () => () => {
      ref.current = watchState;
    },
    [watchState]
  );

  return ref;
};

export const useDebounceSync = <T extends any>(fllowValue: T, ms = 500): T => {
  const [value, setValue] = useState(fllowValue);
  const debouncer = useDebounce();
  useLayoutEffect(() => {
    (async () => {
      if (!(await debouncer.asyncDebounce(ms))) return;
      setValue(fllowValue);
    })();
  }, [debouncer, fllowValue, ms]);

  return value;
};

export const useTimeout = () => {
  const [isTimeout, setIsTimeout] = useState(false);
  const [timer, setTimer] = useState<{
    nodeTimeout: NodeJS.Timeout;
    timsStamp: number;
  }>();

  const clear = useCallback(() => setTimer(undefined), []);
  const wind = useCallback((timeout: number) => {
    setTimer({
      nodeTimeout: setTimeout(() => setIsTimeout(true), timeout),
      timsStamp: new Date().valueOf(),
    });
  }, []);
  const rewind = useCallback((timeoutProp: number, keepRemain?: boolean) => {
    setTimer((nowTimer) => {
      const nowTimeStamp = new Date().valueOf();
      const timeout =
        keepRemain && nowTimer
          ? timeoutProp - (nowTimeStamp - nowTimer.timsStamp)
          : timeoutProp;

      return {
        nodeTimeout: setTimeout(() => setIsTimeout(true), timeout),
        timsStamp: nowTimeStamp,
      };
    });
  }, []);

  useEffect(() => {
    setIsTimeout(false);
    return () => clearTimeout(timer?.nodeTimeout);
  }, [timer]);

  return {
    clear,
    wind,
    rewind,
    isTimeout,
  };
};
