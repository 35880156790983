import { ROUTE_PATH } from 'helpers/constants';
import { useLocation } from 'react-router-dom';

export const useDetailPath = () => {
  const { pathname } = useLocation();

  if (pathname.includes('/property/detail')) {
    return ROUTE_PATH.PROPERTY_LIST;
  }

  if (pathname.includes('/reservation/detail')) {
    return ROUTE_PATH.RESERVATION_LIST;
  }

  if (pathname.includes('/partner-inquiry/detail')) {
    return ROUTE_PATH.PARTNER_INQUIRIES;
  }

  if (pathname.includes('/partner-inquiry/templates')) {
    return ROUTE_PATH.PARTNER_INQUIRY_TEMPLATES;
  }

  if (pathname.includes('/notice/detail')) {
    return ROUTE_PATH.NOTICE_LIST;
  }

  return pathname;
};
