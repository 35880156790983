import type * as React from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { endOfDay, startOfDay } from 'date-fns';
import { UseFormSetValue } from 'react-hook-form';
import { ChannelList } from '../types/channel.type';
import { PropertyChannelMarkupType } from '../types/property.type';

// Reference https://blog.naver.com/hopegiver/140014039330
export const validateBusinessNumber = (
  businessNumberString: string
): boolean => {
  if (businessNumberString.length !== 10 || /[^0-9]/.test(businessNumberString))
    return false;

  const digitList = businessNumberString.split('').map((v) => parseInt(v));
  const checkSum =
    [1, 3, 7, 1, 3, 7, 1, 3, 5].reduce(
      (acc, multiplyValue, index) => acc + digitList[index] * multiplyValue,
      0
    ) + Math.floor(((digitList.at(-2) as number) * 5) / 10);

  return 10 - (checkSum % 10) === digitList.at(-1);
};

/**
 * @example
 * ```ts
 * const phoneNumber = maskString('###-####-####')
 * phoneNumber.mask('01012345678'); // <= '010-1234-5678'
 * phoneNumber.unMask('010-1234-5678'); // <= '01012345678'
 * ```
 */
export const maskString = (pattern: string) => {
  const separatorList = pattern.match(/[^#]+/g) || [];
  const magicStringList = pattern.split(/[^#]/);
  const magicStringLength = magicStringList.join().length;

  return {
    mask: (sentence: string, fillString?: string) => {
      const paddedSentence = sentence.padEnd(
        fillString ? magicStringLength : 0,
        fillString
      );

      let count = 0;
      let result = '';
      for (let index = 0; index < magicStringList.length; index++) {
        const magicString = magicStringList[index];
        const nextCount = count + magicString.length;

        result += paddedSentence.slice(count, nextCount);
        if (paddedSentence.length > nextCount)
          result += separatorList?.[index] || '';
        else break;

        count = nextCount;
      }

      return result;
    },
    unMask: (sentence: string) => {
      let count = 0;
      let result = '';

      for (let index = 0; index < magicStringList.length; index++) {
        const magicString = magicStringList[index];
        const separator = separatorList[index] || '';

        const nextCount = count + magicString.length;

        result += sentence.slice(count, nextCount);
        if (sentence.length < nextCount) break;

        count = nextCount + separator.length;
      }

      return result;
    },
  };
};

export class Debounce {
  private resolver: null | ((p: boolean) => void) = null;

  asyncDebounce = (timeout: number) => {
    if (this.resolver) this.resolver(false);

    return new Promise<boolean>((res) => {
      this.resolver = res;

      setTimeout(() => res(true), timeout);
    });
  };

  clear = () => {
    this.resolver?.(false);
  };
}

export const destructSingleField = <
  T extends { [k in string]?: any } | undefined | null,
  Return = DestructGqlResult<T>
>(
  singleFieldObject: T,
  fallback: Return extends Array<any> ? [] : {}
): Return =>
  singleFieldObject?.[Object.keys(singleFieldObject)?.[0]] ?? fallback ?? {};

export const stopPropagation = (event: any) => {
  if (event?.nativeEvent instanceof Event || event instanceof Event)
    (event as Event)?.stopPropagation();
};
export const withStopPropagation =
  <T extends any>(callBack?: (e: T) => void) =>
  (event: T) => {
    stopPropagation(event);
    callBack?.(event);
    return event;
  };

export const mergeRefs =
  <T = any>(
    ...refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
  ): React.RefCallback<T> =>
  (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };

export const numberWithCommas = (
  x: any,
  postfix?: string | null,
  { float = false } = {}
) =>
  (typeof x === 'number' &&
    `${
      (float && x) ||
      Math.floor(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }${postfix || ''}`) ||
  '';

export const formatPhone = (phone: string) => {
  if (/010[0-9]{8}/.test(phone)) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
  }

  return phone;
};

/**
 * 양쪽모두 같은 class의 instance일때는 커버하지 못합니다. 그럴경우, 이 함수 외에서 판단하세요
 */
export const recurseCompare = <T extends any>(a: T, b: T): boolean => {
  // 양쪽이 class일 경우를 커버하기 위해 사용합니다.
  if (a?.constructor?.name !== b?.constructor?.name) {
    return true;
  }
  if (typeof a !== 'object') return a !== b;

  if (a instanceof Array) {
    return (
      a.findIndex((aItem, index) =>
        recurseCompare(aItem, (b as Array<any>)[index])
      ) !== -1
    );
  }

  // object
  const _a = a as { [k in string]: any };
  const _b = b as { [k in string]: any };
  const aKeys = Object.keys(_a);
  const bKeys = Object.keys(_b);
  if (aKeys.length !== bKeys.length) return true;

  for (const key of aKeys) {
    if (recurseCompare(_a[key], _b[key])) return true;
  }

  return false;
};

export const toastNotify = (
  toastId: string | number,
  toastContent: ToastContent,
  toastOptions: ToastOptions
) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: toastContent, ...toastOptions });
  } else {
    toast(toastContent, { toastId, ...toastOptions });
  }
};

export const filterNullable = <T>(
  argument: T | null | undefined
): argument is T => argument !== undefined && argument !== null;

export const compareCharacterASCSort = (a: string, b: string): -1 | 1 => {
  const minLength = Math.min(a.length, b.length);

  for (let i = 0; i < minLength; i++) {
    const aCharCode = a[i].charCodeAt(0);
    const bCharCode = b[i].charCodeAt(0);
    if (aCharCode > bCharCode) return 1;
    if (aCharCode < bCharCode) return -1;
  }

  return a.length <= b.length ? -1 : 1;
};

type ChannelSubscriber<Message extends any> = (
  message: Message,
  unsubscribe: () => void
) => void;
export class Channel<Message extends any> {
  readonly channel = new Set<ChannelSubscriber<Message>>();

  public async broadcast(message: Message) {
    for (const subscriber of Array.from(this.channel)) {
      subscriber(message, () => this.channel.delete(subscriber));
    }
  }

  public subscribe(subscriber: ChannelSubscriber<Message>) {
    this.channel.add(subscriber);

    return () => this.unsubscribe(subscriber);
  }

  public unsubscribe(subscriber: ChannelSubscriber<Message>) {
    this.channel.delete(subscriber);
  }

  public clear() {
    this.channel.clear();
  }
}

export const classes = (
  ...args: Array<undefined | null | string | boolean>
): string => {
  return args
    .flat()
    .filter((x) => typeof x === 'string')
    .join(' ');
};

export const parseNumberWithCommas = (amount: string) =>
  parseFloat(amount?.toString?.().replace(/,/g, '')) || 0;

export const restrictInputNumberOnly =
  ({
    withComma,
    onChange,
    maxValue,
    minValue,
    numeric,
    formatOnMinMax = true,
    allowMinus = false,
    float = false,
  }: {
    withComma?: boolean;
    onChange: (value: string) => void;
    maxValue?: number;
    minValue?: number;
    numeric?: boolean;
    formatOnMinMax?: boolean;
    allowMinus?: boolean;
    float?: boolean;
  }) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.split('').pop() === '.') {
      return onChange(event.target.value);
    }

    const amountValue = numeric
      ? parseNumberWithCommas(event.target.value)
      : event.target.value?.replace?.(/[^0-9]/g, '');

    if (minValue && amountValue < minValue) {
      event.target.onblur = () => onChange(formatValue(minValue)); // eslint-disable-line
    } else {
      event.target.onblur = null; // eslint-disable-line
    }

    if (
      /-/.test(event.target.value) &&
      /^[0-]{1,2}$/.test(event.target.value) &&
      allowMinus
    ) {
      return onChange('-');
    }

    const formatValue = (targetVal?: string | number) =>
      `${
        withComma
          ? numberWithCommas(targetVal || amountValue, null, { float })
          : targetVal || amountValue
      }`;

    if (!minValue || amountValue > minValue) {
      if (maxValue && amountValue < maxValue) {
        return onChange(formatValue());
      }
      if (maxValue) {
        if (!formatOnMinMax) return undefined;
        return onChange(formatValue(maxValue));
      }
    }

    if (minValue && amountValue > minValue) {
      return onChange(formatValue());
    }

    if (minValue) {
      if (!formatOnMinMax) return undefined;

      return onChange(formatValue());
    }
    return onChange(formatValue());
  };

export const numberOnlyRegister = (
  registerKey: string,
  setValue: UseFormSetValue<any>
) =>
  restrictInputNumberOnly({
    onChange: (newValue) => {
      setValue(registerKey, Number(newValue));
    },
  });

export const createDatePresets = () => {
  const DATE_PRESETS = {
    today: {
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
    },
    yesterday: {
      from: startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      to: endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
    },
    last7days: {
      from: startOfDay(new Date(new Date().setDate(new Date().getDate() - 7))),
      to: endOfDay(new Date()),
    },
    last30days: {
      from: startOfDay(new Date(new Date().setDate(new Date().getDate() - 30))),
      to: endOfDay(new Date()),
    },
    thisMonth: {
      from: startOfDay(new Date(new Date().setDate(1))),
      to: endOfDay(new Date()),
    },
  };

  return DATE_PRESETS;
};

export function convertPhone(value: string) {
  const result = value.replace(/\D/g, '');

  if (result.length < 7) {
    return result.replace(/(^02.{0}|^01.{1}|\d{3})(\d+)/, '$1-$2');
  }

  if (result.length === 8) {
    return result.replace(/(\d{4})(\d+)/, '$1-$2');
  }

  if (result.length < 11) {
    return result.replace(/(^02.{0}|^01.{1}|\d{3})(\d{3})(\d+)/, '$1-$2-$3');
  }

  return result.replace(/(^02.{0}|^01.{1}|\d{3})(\d{4})(\d+)/, '$1-$2-$3');
}

export function isSmsFailed(smsCallStatus: number) {
  if (smsCallStatus) {
    return smsCallStatus !== 6600;
  }

  return false;
}

export function isHomepageRequired(channelList: ChannelList[]) {
  return channelList.find(item => ["74", "77"].includes(item?.id))
}

export function makeChannelList(channelList: PropertyChannelMarkupType[], sortedChannels: number[]) {
  return sortedChannels.map(item => {
    return channelList.find(channel => channel.channelId === item.toString())!;
  });
}

export function isEqualOpenStatus(targetStatus: string, type: 'ON' | 'OFF') {
  return targetStatus.includes(type);
}

export function convertBusinessNumber(value: string) {
  const result = value.replace(/\D/g, '');

  if (result.length >= 4 && result.length < 7) {
    return result.replace(/(\d{3})(\d+)/, '$1-$2');
  }

  if (result.length >= 7) {
    return result.replace(/(\d{3})(\d{2})(\d+)/, '$1-$2-$3');
  }

  return result;
}

export function convertRegistrationNumber(value: string) {
  const result = value.replace(/\D/g, '');

  if (result.length >= 7) {
    return result.replace(/(\d{6})(\d+)/, '$1-$2');
  }

  return result;
}

export const ellipsis = (str: string, len: number) => {
  if (str.length <= len) return str;

  return `${str.slice(0, len)}...`;
};

export const getExifRemovedImageFile = async (file: File) => {
  // render image on canvas and convert it to a new file blob causes exif metadata to be removed

  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      const { width, height } = img;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0);

      canvas.toBlob(
        (blob) => {
          if (!blob) return reject();

          return resolve(
            new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            })
          );
        },
        file.type,
        1
      );
    };
  });
};
