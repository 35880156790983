// polyfill
import 'core-js/es/array/at';
import 'core-js/es/array/flat-map';
import 'core-js/es/array/flat';
import 'helpers/i18next.config';

import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'styles/appVariables.scss';
import 'styles/index.css';
import 'styles/reset.scss';
import 'react-toastify/dist/ReactToastify.css';
import { setDefaultLocale, registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/locale';
import { createPortal } from 'react-dom';

registerLocale('ko', ko);
setDefaultLocale('ko');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    {createPortal(
      <ToastContainer
        position="bottom-right"
        closeOnClick
        draggable
        autoClose={3000}
      />,
      document.body
    )}
  </React.StrictMode>
);
