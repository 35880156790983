import React, { Suspense, useEffect } from 'react';
import ErrorBoundary, { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'helpers/apolloClient';
import AppRoutes from 'routes/AppRoutes';
import { BrowserRouter, Routes } from 'react-router-dom';
import { EllipsisLoader } from 'components/ui/Loader';
import { MotionConfig } from 'framer-motion';
import { isDevelopment, PERMERNENT_STATE_KEY } from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import { usePermernentState } from 'hooks';
import { TimeoutRender } from 'components/LazyRender';
import { ProvideAuth } from 'helpers/useAuth';

const AppFallBack: ErrorBoundaryFallback = ({ error, resetError }) => {
  const { t } = useTranslation();
  const [isRefreshedCauseChunkError, setIsRefreshedCauseChunkError] =
    usePermernentState({
      key: PERMERNENT_STATE_KEY.APP_REFRESH_REQUESTED_CAUSE_CUNK_ERROR,
      decode: Boolean,
      encode: String,
      storage: sessionStorage,
    });

  useEffect(() => {
    if (
      document.location.hostname !== 'localhost' &&
      !isRefreshedCauseChunkError &&
      error.name.includes('ChunkLoadError')
    ) {
      setIsRefreshedCauseChunkError(true);
      window.location.reload();
    }
  }, [error, isRefreshedCauseChunkError]);

  return (
    <section className="m-auto max-w-full w-[390px] px-ct-indent py-16 rounded-xl bg-bg-sub text-center">
      <p aria-hidden className="text-6xl font-accent">
        {t('app.crash.main-text')}
      </p>
      <h2 className="font-medium my-7 text-xl whitespace-pre-wrap">
        {t('app.crash.heading')}
      </h2>
      <button
        onClick={resetError}
        className="inline-block p-3 min-w-[180px] text-xl text-bg-contrast font-medium bg-primary rounded"
      >
        {t('app.crash.button.retry')}
      </button>
      {isDevelopment && (
        <div className="bg-black bg-opacity-70 fixed inset-0 flex overflow-auto">
          <div className="whitespace-pre-line break-words p-10 m-auto bg-bg">
            <p className="font-bold text-3xl">{error.name}</p>
            <p className="mb-5 mt-2 text-error text-2xl">{error.message}</p>
            <p className="text-lg">{error.stack}</p>
            <button
              onClick={resetError}
              className="inline-block p-3 min-w-[180px] text-xl text-bg-contrast font-medium bg-primary rounded mt-10"
            >
              {t('app.crash.button.retry')}
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

const AppLoader = () => (
  <TimeoutRender>
    <EllipsisLoader className="m-auto text-primary text-xl tablet:text-2xl" />
  </TimeoutRender>
);

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <MotionConfig transition={{ duration: 0.2 }} reducedMotion="user">
        <BrowserRouter>
          <ProvideAuth>
            <ErrorBoundary fallback={AppFallBack}>
              <Suspense fallback={<AppLoader />}>
                <Routes>{AppRoutes}</ Routes>
              </Suspense>
            </ErrorBoundary>
          </ProvideAuth>
        </BrowserRouter>
      </MotionConfig>
    </ApolloProvider>
  );
}

export default App;
