import { create } from 'zustand';

export const useVendorSelectionStore = create<{
  selectedGlobalVendor: string | null;
  selectVendor: (vendor: string) => void;
  initialize: () => void;
}>((set) => ({
  selectedGlobalVendor: null,
  selectVendor: (vendor: string) => set({ selectedGlobalVendor: vendor }),
  initialize: () => set({ selectedGlobalVendor: null }),
}));
