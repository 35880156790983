import React, { PropsWithChildren } from 'react';

export type ErrorBoundaryFallback = React.ComponentType<
  PropsWithChildren<{ error: Error; resetError: () => void }>
>;

type Props = PropsWithChildren<{ fallback: ErrorBoundaryFallback }>;

class ErrorBoundary extends React.Component<Props, { error?: Error }> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      const { fallback: Fallback, children } = this.props;

      return (
        <Fallback
          error={error}
          resetError={() => this.setState({ error: undefined })}
        >
          {children}
        </Fallback>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
