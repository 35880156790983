import { useNavigate } from 'react-router-dom';
import { ReactComponent as PPLogoWhite } from 'assets/images/pp-logo-white.svg';
import { useAuth } from 'helpers/useAuth';
import { SideBarMenuPreset } from 'types/layout.type';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { classes } from 'helpers/utils';
import { ROUTE_PATH } from 'helpers/constants';
import { useDetailPath } from 'hooks/useDetailPath';
import tw, { styled } from 'twin.macro';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid';

const SIDEBAR_MENU_PRESET: SideBarMenuPreset[] = [
  {
    label: 'sidebar.menu.reservation.list',
    route: ROUTE_PATH.RESERVATION_LIST,
  },
  {
    label: 'sidebar.menu.properties',
    route: ROUTE_PATH.PROPERTY_LIST,
    subMenus: [
      {
        label: 'sidebar.menu.properties.list',
        route: ROUTE_PATH.PROPERTY_LIST,
      },
      {
        label: 'sidebar.menu.report',
        route: ROUTE_PATH.REPORT,
      },
      {
        label: 'sidebar.menu.properties.download',
        route: ROUTE_PATH.PROPERTY_DOWNLOAD,
        permission: {
          vendorIds: [16],
        },
      },
    ],
  },
  {
    label: 'sidebar.menu.properties.partner-inquiry',
    route: ROUTE_PATH.PARTNER_INQUIRIES,
    permission: {
      authority: 'admin',
    },
    subMenus: [
      {
        label: 'sidebar.menu.partner-inquiry.list',
        route: ROUTE_PATH.PARTNER_INQUIRIES,
      },
      {
        label: 'sidebar.menu.partner-inquiry.templates',
        route: ROUTE_PATH.PARTNER_INQUIRY_TEMPLATES,
      },
    ],
  },
  {
    label: 'sidebar.menu.sms-charge',
    route: ROUTE_PATH.SMS_CHARGE_LIST,
    permission: {
      authority: 'admin',
    },
    subMenus: [
      {
        label: 'sidebar.menu.sms-charge.list',
        route: ROUTE_PATH.SMS_CHARGE_LIST,
      },
    ],
  },
  {
    label: 'sidebar.menu.notices',
    route: ROUTE_PATH.NOTICE_LIST,
    permission: {
      authority: 'admin',
    },
  },
];

export const SidebarPlaceHolder = styled.div`
  ${tw`relative w-[240px] shrink-0 h-full`}
`;

const ACTIVE_ROUTE_CLASS =
  'bg-primary bg-opacity-100 hover:bg-primary hover:bg-opacity-100 before:bg-white before:bg-opacity-100 before:h-[20px] before:w-1 before:absolute';

export const DashboardLeftSidebar = () => {
  const { permission, signOut, getVendorIds } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const detailPath = useDetailPath();

  // @ts-ignore
  window.auth = useAuth();

  const onClickRoute = useCallback((route: string) => {
    navigate(route);
  }, []);

  const checkMenuPermission = useCallback(
    (menu: any) => {
      if (
        menu.permission?.authority &&
        menu.permission?.authority !== permission
      ) {
        return false;
      }
      if (
        menu.permission?.vendorIds &&
        !(
          getVendorIds()?.some((id) =>
            menu.permission?.vendorIds?.includes(id)
          ) || getVendorIds()?.includes('*')
        )
      ) {
        return false;
      }

      return true;
    },
    [getVendorIds]
  );

  return (
    <>
      <SidebarPlaceHolder />
      <div className="bg-bg-primary h-full text-bg-contrast w-[240px] flex flex-col pt-[92px] items-center z-[1000] fixed left-0 flex-shrink-0">
        <PPLogoWhite
          className="w-[164px] mb-11 cursor-pointer"
          onClick={() => navigate(ROUTE_PATH.RESERVATION_LIST)}
        />
        {SIDEBAR_MENU_PRESET.map((menu) => {
          if (!checkMenuPermission(menu)) {
            return null;
          }
          return (
            <React.Fragment key={menu.label}>
              <a
                onClick={() => onClickRoute(menu.route)}
                className={classes(
                  'w-full h-12 flex items-center justify-center px-9 cursor-pointer mt-5 hover:bg-black hover:bg-opacity-10 before:left-6',
                  detailPath === menu.route && !menu.subMenus?.length
                    ? ACTIVE_ROUTE_CLASS
                    : ''
                )}
              >
                <div className="w-full h-full flex items-center justify-start">
                  <p className="text-caption2 text-bg-contrast">
                    {t(menu.label)}
                  </p>
                </div>
              </a>
              {menu.subMenus?.map((subMenu) => {
                if (!checkMenuPermission(subMenu)) {
                  return null;
                }
                return (
                  <a
                    key={subMenu.label}
                    onClick={() => onClickRoute(subMenu.route)}
                    className={classes(
                      'w-full h-10 flex items-center justify-center px-[60px] cursor-pointer hover:bg-black hover:bg-opacity-10 before:left-10',
                      detailPath === subMenu.route ? ACTIVE_ROUTE_CLASS : ''
                    )}
                  >
                    <div className="w-full h-full flex items-center justify-start">
                      <p className="text-caption3 text-bg-contrast">
                        {t(subMenu.label)}
                      </p>
                    </div>
                  </a>
                );
              })}
            </React.Fragment>
          );
        })}

        <div className="flex-grow" />
        <a
          onClick={signOut}
          className="w-full h-12 flex items-center justify-center px-9 cursor-pointer mt-5 hover:bg-black hover:bg-opacity-10 before:left-6 mb-10"
        >
          <div className="w-full h-full flex items-center justify-start">
            <ArrowRightOnRectangleIcon color="white" width={24} />
            <p className="text-caption2 text-bg-contrast ml-2">
              {t('common.signOut')}
            </p>
          </div>
        </a>
      </div>
    </>
  );
};
