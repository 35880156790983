import { ROUTE_PATH } from 'helpers/constants';
import React from 'react';
import { useAuth } from 'helpers/useAuth';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
  element,
  permissions,
}: {
  element: React.ReactNode;
  permissions?: string[];
}) => {
  const { permission } = useAuth();

  if (permission === 'loading') {
    return null;
  }

  if (!permission) {
    return <Navigate replace to={ROUTE_PATH.SIGN_IN} />;
  }

  if (permissions?.length && !permissions.includes(permission)) {
    return <Navigate replace to={ROUTE_PATH.RESERVATION_LIST} />;
  }

  return <>{element}</>;
};
