import React, { Fragment, useMemo, useState } from 'react';
import { ReactComponent as DownChevronIcon } from 'assets/icons/down.svg';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

export type SelectBoxProps = {
  options: {
    label: string;
    value: any;
  }[];
  value?: any;
  onChange?: (value: any) => void;
  className?: string;
  emptyLabel?: string;
  disabled?: boolean;
};

export const SelectBoxHeader = ({
  value,
  options,
  onChange,
  className,
  disabled,
  emptyLabel,
}: SelectBoxProps) => {
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const currentOption = useMemo(() => {
    return options.find((option) => option.value === value) || options[0];
  }, [value]);

  return (
    <Listbox
      disabled={disabled}
      value={value}
      onChange={(changeEvent) => {
        setSearchValue('');
        onChange?.(changeEvent);
      }}
    >
      <div
        className={twMerge(
          `relative w-[180px] cursor-pointer hover:bg-black hover:bg-opacity-10 h-10 items-center flex rounded`,
          className
        )}
      >
        <Listbox.Button className="relative w-full">
          <input
            value={
              searchValue || (searching ? searchValue : currentOption.label)
            }
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={() => setSearching(true)}
            onBlur={() => {
              setSearching(false);
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className="block truncate mr-8 pl-3"
          />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <DownChevronIcon aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={searching || !!searchValue}
        >
          <Listbox.Options className="absolute cursor-pointer top-full max-h-60 w-full top-0 overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {[
              ...((emptyLabel && [{ label: emptyLabel, value: '' }]) || []),
              ...options,
            ]
              .filter(
                (item) => !searchValue || item.label.includes(searchValue)
              )
              .map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    `relative select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-bg-gray' : ''
                    }`
                  }
                  value={option.value}
                >
                  {({ selected: isSelected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          isSelected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.label}
                      </span>
                      {isSelected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export const SelectBoxOutlined = ({
  value,
  options: rawOptions,
  onChange,
  className,
  disabled,
  emptyLabel,
  viewAllLabel,
  disableViewAll,
}: SelectBoxProps & { disableViewAll?: boolean; viewAllLabel?: string }) => {
  const options = [
    ...((emptyLabel && [{ label: emptyLabel, value: '' }]) || []),
    ...rawOptions,
  ];

  const currentOption = useMemo(() => {
    return options.find((option) => option.value === value) || options[0];
  }, [value]);

  return (
    <Listbox disabled={disabled} value={value} onChange={onChange}>
      <div
        className={twMerge(
          `relative w-[180px] cursor-pointer border border-border-gray-light rounded h-10 flex item-center px-4`,
          disabled && 'bg-bg-gray-dark',
          className
        )}
      >
        <Listbox.Button className="relative w-full text-left">
          <span className="block truncate text-caption3 mr-6">
            {currentOption.label}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
            <DownChevronIcon className="w-5" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute cursor-pointer top-full left-0 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
            {[
              ...((emptyLabel &&
                !disableViewAll && [
                  { label: viewAllLabel || '전체보기', value: '' },
                ]) ||
                []),
              ...rawOptions,
            ].map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 px-4 ${
                    active ? 'bg-bg-gray' : ''
                  }`
                }
                value={option.value}
              >
                {({ selected: isSelected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        isSelected ? 'font-medium text-primary' : 'font-normal'
                      }`}
                    >
                      {option.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
