import { keyframes } from '@emotion/react';
import React from 'react';
import { styled } from 'twin.macro';

const EllipsisLoaderKeyframe1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const EllipsisLoaderKeyframe2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.6em, 0);
  }
`;
const EllipsisLoaderKeyframe3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const StyledEllipsisLoader = styled.div`
  position: relative;
  display: flex;
  gap: 0.8em;
  width: 4em;

  > div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:first-of-type {
      position: absolute;
    }
    &:nth-of-type(1) {
      animation: ${EllipsisLoaderKeyframe1} 0.6s infinite;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      animation: ${EllipsisLoaderKeyframe2} 0.6s infinite;
    }
    &:nth-of-type(4) {
      animation: ${EllipsisLoaderKeyframe3} 0.6s infinite;
    }
  }
`;
export const EllipsisLoader = (
  props: Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'ref' | 'children'>
) => (
  <StyledEllipsisLoader {...props}>
    <div />
    <div />
    <div />
    <div />
  </StyledEllipsisLoader>
);

const RadioWaveLoaderKeyFrame = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;
export const RadioWaveLoader = styled.div`
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;

  &::after,
  &::before {
    content: '';
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    animation: ${RadioWaveLoaderKeyFrame} 2s linear infinite;
  }
  &::after {
    animation-delay: 1s;
  }
`;

export const keyframesShadowLoader = keyframes`
  from{
      background-position: -800px 0
  }
  to{
      background-position: 800px 0
  }
`;

export const ShadowLoader = styled.span`
  animation-duration: 1.25s;
  border-radius: 8px;
  display: block;
  flex-shrink: 0;
  opacity: 0.4;
  background: ${(props) => props.color || 'rgba(0, 0, 0, 0.4)'};
  &:not([data-static='true']) {
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${keyframesShadowLoader};
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      ${(props) => props.color || 'rgba(0, 0, 0, 0.4)'} 8%,
      ${(props) => props.color || 'rgba(0, 0, 0, 0.15)'} 18%,
      ${(props) => props.color || 'rgba(0, 0, 0, 0.4)'} 33%
    );
    background-size: 1600px 104px;
  }
  position: relative;
`;
