import { TportQueryVendorRows } from 'graphql/query/vendor.query';
import { useQuery } from '@apollo/client';
import { VendorList } from 'types/vendor.type';
import { PPMS_VENDOR } from 'helpers/constants';

export const useVendorList = () => {
  const queryVendorRows = useQuery<{ vendorList: VendorList[] }>(
    TportQueryVendorRows
  );

  return (
    queryVendorRows.data?.vendorList.filter((item) =>
      PPMS_VENDOR.includes(Number(item.id))
    ) || []
  );
};
